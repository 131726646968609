<template>
  <div class="media-card flex rounded-lg mb-4 overflow-hidden">
    <figure
      v-if="image"
      class="thumbnail flex-shrink-0"
    >
      <img
        class="w-full h-full object-cover"
        :src="image"
        :alt="title"
      >
    </figure>
    <div class="content px-0 py-3 ml-6 flex-grow flex flex-col justify-center">
      <h3
        v-if="title"
        class="media-title"
      >{{ title }}</h3>
      <p
        v-if="subTitle"
        class="media-subtitle"
      >
        {{ subTitle }}
      </p>
      <p
        v-if="description"
        class="media-description"
      >{{ description }}</p>
    </div>
    <slot>
      <div class="arrow flex-shrink-0 relative media-card__arrow">
        <BaseIcon
          icon="arrow-right"
          class="icon absolute"
        />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.media-card {
  min-height: 102px;
  border: 1px solid #eaeaea;

  .media-title {
    @apply my-1 font-semibold text-base;
  }

  .media-subtitle {
    @apply text-gray-500;
  }

  .media-subtitle,
  .media-description {
    @apply mb-1 font-normal text-base;
  }

  .thumbnail {
    width: 128px;
    background: #dad7cb;
  }

  .arrow {
    width: 64px;
    transition: transform 0.15s ease-in-out;
    .icon {
      font-size: 1rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .arrow {
      transform: translateX(5px);
    }
  }
}
</style>
