<template>
  <NuxtLink
    data-test="programCard"
    :aria-label="title"
    :to="href"
  >
    <MediaCard
      :image="image && image.url"
      :title="title"
      :description="program.catalogDescription || program.description"
    />
  </NuxtLink>
</template>

<script>
import { mapState } from 'vuex'
import MediaCard from '@/components/MediaCard'
import { generateProgramLabel } from '@/utils/dataLabels'
import useInterpolation from '@/composables/useInterpolation'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { TITLE_INTERPOLATIONS } from '@/constants/featureFlags'

export default {
  name: 'ProgramCard',
  components: {
    MediaCard,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
    href: {
      type: [String, Object],
      required: true,
    },
  },
  setup() {
    const { enableTitleInterpolations } = useFeatureFlags({
      enableTitleInterpolations: TITLE_INTERPOLATIONS,
    })
    const { getInterpolatedFieldValue } = useInterpolation()
    return {
      getInterpolatedFieldValue,
      enableTitleInterpolations,
    }
  },
  computed: {
    ...mapState('settings', ['school']),
    image() {
      return typeof this.program.catalogImageUrl === 'string'
        ? { url: this.program.catalogImageUrl }
        : this.program.catalogImageUrl
    },
    title() {
      return generateProgramLabel(this.program, this.school, {
        getInterpolatedFieldValue: this.enableTitleInterpolations
          ? this.getInterpolatedFieldValue
          : undefined,
        nameOnlyFallback: true,
      })
    },
  },
}
</script>
