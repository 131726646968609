<template>
  <component
    :is="elementTagType"
    v-bind="attributes"
  >
    <div
      :class="{ 'card-featured transform hover:scale-95': featured }"
      class="relative rounded-lg overflow-hidden border-gray-100 border card-shadow cursor-pointer transition-all duration-300 ease-in-out"
      @click="$emit('click')"
    >
      <div
        class="relative overflow-hidden pb-48 bg-gray-200"
      >
        <img
          v-if="url"
          class="absolute h-full w-full object-cover object-center"
          :src="url"
          :alt="altText"
        />
      </div>
      <div
        class="card-body"
        :class="{ 'flex absolute w-full h-full items-center justify-center text-center text-white': featured }"
      >
        <div class="p-4">
          <h3
            class="mb-2"
            :class="{ 'text-2xl': featured }"
          >{{ title }}</h3>
          <p
            v-if="description && !featured"
            class="text-sm"
          >{{ description }}</p>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { isObject } from '@/utils/generalUtils'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { NO_DEFAULT_IMAGE } from '@/constants/featureFlags'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: [String, Object],
      default: null,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: null,
    },
    href: {
      type: [Object, String],
      default: null,
    },
  },
  setup() {
    const { noDefaultImage } = useFeatureFlags({
      noDefaultImage: NO_DEFAULT_IMAGE,
    })
    return { noDefaultImage }
  },
  computed: {
    isAnchor() {
      return Boolean(this.href || this.to)
    },
    elementTagType() {
      if (this.isAnchor) {
        return 'NuxtLink'
      } else {
        return 'section'
      }
    },
    attributes() {
      return {
        ...(this.isAnchor && {
          to: this.to || this.href,
        }),
      }
    },
    url() {
      return isObject(this.imageProxy) ? this.imageProxy.url : this.imageProxy
    },
    altText() {
      return isObject(this.imageProxy) ? this.imageProxy.altText : null
    },
    imageProxy() {
      // remove when noDefaultImage flag is no loger used, restore usage of `image` prop everywhere
      return this.noDefaultImage
        ? this.image
        : this.image || 'https://source.unsplash.com/random'
    },
  },
}
</script>

<style scoped>
.card-shadow {
  box-shadow: 2px 2px 12px rgb(0 0 0 / 5%);
}
.card-shadow:hover {
  box-shadow: 5px 5px 20px rgb(0 0 0 / 10%);
}

.card-featured .card-body {
  bottom: 0;
  background: -webkit-linear-gradient(to bottom, #ffffff00, rgb(0 0 0 / 75%));
  background: linear-gradient(to bottom, #ffffff00, rgb(0 0 0 / 75%));
}

h3 {
  color: var(--h3HeaderFontColor, inherit);
  font-family: var(--h3HeaderFontTypeface, inherit);
  font-weight: var(--h3HeaderFontWeight, 800);
}
p {
  font-family: var(--textFontTypeface, inherit);
  color: var(--textFontColor, var(--text-gray-600));
  font-weight: var(--textFontWeight, inherit);
}
</style>
