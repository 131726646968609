<template>
  <ProgramSearchPage :filter-fields="filterFields" />
</template>

<script>
import ProgramSearchPage from '~contemporaryTheme/pages/programs/index'

export default {
  components: {
    ProgramSearchPage,
  },
  computed: {
    filterFields() {
      return [
        {
          label: 'Degree',
          questionId: 'degreeDesignation',
          dataKey: 'degreeDesignation',
          custom: false,
          type: 'radio',
          questionType: 'select',
          config: {
            showAllOptions: true,
          },
          visibility: true,
          tooltipEnabled: false,
          filter: {
            group: 'program',
            id: 'degreeDesignation-programs',
            inputType: 'select',
            name: 'degreeDesignation',
            type: 'is',
          },
        },
      ]
    },
  },
}
</script>
