<template>
  <div>
    <div class="max-w-screen-xl pl-4 py-4 mx-auto flex programs__container">
      <SkipToMain href="#programs-list" label="Skip to Programs List" />
      <SidebarWrapper
        :open="filterMenuOpened"
        class="filter-menu sidebar"
        name="Program Filters"
        @close="closeFilterMenu"
      >
        <template #sidebar-heading>
          <div>
            <div class="flex items-start">
              <h1 class="text-xl flex-1 mr-2 heading-1">Programs</h1>
              <button
                type="button"
                aria-label="Close"
                class="md:hidden"
                @click="closeFilterMenu"
              >
                <BaseIcon icon="times" />
              </button>
            </div>
            <button
              v-if="isFiltering || searchQuery"
              type="button"
              class="mb-2 bg-red-600 text-white	hover:opacity-100 opacity-80 rounded-full px-2 py-1 transition ease-out duration-200  pill-label text-xs"
              @click="clearFilters"
            >
              <BaseIcon icon="times-circle" />
              Clear Filters
            </button>
          </div>
        </template>
        <div class="mb-8">
          <FilterInput
            v-model="searchQuery"
            placeholder="Type to search programs..."
            label="Search Programs"
            class="w-full"
            :config="{ type: 'search' }"
          >
            <template #iconStart>
              <BaseIcon
                icon="search"
                class="color-deep-black"
              />
            </template>
          </FilterInput>
        </div>
        <div
          class="overflow-y-auto"
          style="height:65vh"
        >
          <Filters
            :filters="filtersDataWithOptions"
            :values="filters"
            @input="filters = $event"
          />
        </div>
      </SidebarWrapper>
      <article
        id="programs-list"
        class="flex-1 px-4"
        name="Additional Program Information"
        :class="{'overflow-y-auto': !filterMenuOpened, 'overflow-hidden':  filterMenuOpened}"
        style="height:80vh"
      >
        <button
          type="button"
          class="filter-button bg-theme-navbar-background color-theme-navbar-link hover:opacity-80 rounded-full px-2 py-1 transition ease-out duration-200 text-xs lg:hidden mb-2"
          @click.stop="filterMenuOpened=true"
        >
          <BaseIcon icon="filter" />
          Filters
        </button>
        <div class="media-card flex rounded-lg mb-0 overflow-hidden mt-6" role="region" aria-live="polite" id="search-status">
          <div class="content px-0 py-3 ml-6 flex-grow flex flex-col justify-center visually-hidden">
            <p v-if="!total && !isLoading && searchQuery">
              No Results
            </p>
            <p v-if="isLoading">
              Loading results...
            </p>
            <p v-if="!isLoading && total">
              {{ total }} results found. Showing {{ (currentPage - 1) * pageSize + 1 }} - {{ currentPage * pageSize > total ? total : currentPage * pageSize }}.
            </p>
          </div>
        </div>
        <div
          v-if="isLoading"
          class="p-20 flex justify-center"
        >
          <BaseSpinner />
        </div>
        <div v-else>
          <div class="flex items-center mb-2 programs__caption-filters">
            <h1 class="text-2xl flex-1">Results ({{ total }})</h1>
            <div class="flex-1" />
            <label class="font-bold mr-2" for="sort-by-select">Sort By</label>
            <FormSelect
              id="sort-by-select"
              :value="sortBy || sortByOptionsWithEmptyValue[0]"
              :config="{ options: sortByOptionsWithEmptyValue }"
              :custom-label="decamelize"
              :hide-empty-value="true"
              @input="updateSortBy"
            />
          </div>
          <div v-if="programsFormatted.length">
            <template v-if="programPageRedesign">
              <ProgramCard
                v-for="(program,i) in programsFormatted"
                :key="i"
                :href="getProgramUrl(program)"
                :program="program"
              />
            </template>
            <AppGrid v-else>
              <AppCard
                v-for="(program,i) in programsFormatted"
                :key="i"
                :href="getProgramUrl(program)"
                :title="programDisplayHeading(program)"
                :image="program.catalogImageUrl"
                data-test="programsListContainer"
              />
            </AppGrid>
            <BasePagination
              v-if="total > pageSize"
              class="mt-4"
              :current-page="currentPage"
              :total-pages="totalPages"
              @page-change="updatePage"
            />
          </div>
          <EmptyState
            v-else
            label="No Programs Found"
            class="flex-1 mt-10"
          />
        </div>
      </article>
    </div>
    <div class="max-w-screen-xl pl-4 mx-auto flex print-list-button">
      <div class="theme-page-content flex justify-center link-container">
        <a v-if="enablePrintList" data-test-id="print-link" class="link text-md" :href="printPath">
          Download Program List as PDF
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef, computed, watch } from '@nuxtjs/composition-api'
import { mapState, mapGetters } from 'vuex'
import _truncate from 'lodash/truncate'
import { mixin as clickaway } from 'vue-clickaway'
import { fillFiltersOptionsFromQuestions } from '@/utils/filters'
import SidebarWrapper from '~contemporaryTheme/components/SidebarWrapper'
import AppGrid from '~contemporaryTheme/components/Base/AppGrid'
import AppCard from '~contemporaryTheme/components/Base/AppCard'
import FilterInput from '~contemporaryTheme/components/Base/FilterInput'
import Filters from '~contemporaryTheme/components/Base/Filters'
import EmptyState from '~contemporaryTheme/components/Base/EmptyState'
import ProgramCard from '~contemporaryTheme/components/ProgramCard'
import SkipToMain from '@/components/SkipToMain'
import usePrograms from '@/composables/usePrograms'
import useFilters from '@/composables/useFilters'
import useFeatureFlags from '@/composables/useFeatureFlags'
import usePrint from '@/composables/usePrint'
import {
  TITLE_INTERPOLATIONS,
  PROGRAM_LANDING_PAGE_REDESIGN,
  PRINT_LIST,
} from '@/constants/featureFlags'
import FormSelect from '@/components/Fields/FormSelect'
import { decamelize } from '@/utils/generalUtils'
import { programNameWithFallbacks } from '@/utils/entityNames'
import { generateProgramLabel } from '@/utils/dataLabels'
import useInterpolation from '@/composables/useInterpolation'

export default {
  setup(props, { root }) {
    const {
      programPageRedesign,
      enableTitleInterpolations,
      enablePrintList,
    } = useFeatureFlags({
      programPageRedesign: PROGRAM_LANDING_PAGE_REDESIGN,
      enableTitleInterpolations: TITLE_INTERPOLATIONS,
      enablePrintList: PRINT_LIST,
    })

    const { getInterpolatedFieldValue } = useInterpolation()

    const { filtersPayload, filtersData, filtersDefaultValues } = useFilters(
      root,
      {
        type: 'programs',
        prebuiltFilters: toRef(props, 'filterFields'),
      }
    )

    const columns = [
      'name',
      'type',
      'degreeDesignation',
      'contacts',
      'longName',
      'catalogDisplayName',
      'transcriptDescription',
      'catalogDescription',
      'catalogImageUrl',
      ...(enableTitleInterpolations ? ['cipCode', 'campus', 'level'] : []),
    ]

    const {
      isLoading,
      search,
      searchDebounced,
      fetchData,
      fetchQuestion,
      programsFormatted,
      filters,
      searchQuery,
      getProgramUrl,
      total,
      totalPages,
      currentPage,
      updatePage,
      pageSize,
      programQuestions,
      sortByOptions,
      sortBy,
      updateQuery,
    } = usePrograms(root, { filtersPayload, columns })

    function updateSortBy(value) {
      const updatedValue = value === '-' ? '' : value

      sortBy.value = updatedValue
      updateQuery({ sortBy: updatedValue })
    }

    function clearFilters() {
      filters.value = {}
      searchQuery.value = null
    }

    const filtersDataWithOptions = computed(() => {
      return fillFiltersOptionsFromQuestions(
        filtersData.value,
        programQuestions.value
      )
    })

    watch(
      filtersDefaultValues,
      () => {
        if (Object.values(filtersDefaultValues).length) {
          filters.value = { ...filters.value, ...filtersDefaultValues.value }
        }
      },
      { immediate: true }
    )

    const { printPath } = usePrint(root)

    return {
      questions: programQuestions,
      isLoading,
      search,
      searchDebounced,
      fetchData,
      fetchQuestion,
      programsFormatted,
      filters,
      searchQuery,
      getProgramUrl,
      total,
      totalPages,
      currentPage,
      updatePage,
      pageSize,
      _truncate,
      clearFilters,
      filtersDataWithOptions,
      programPageRedesign,
      sortByOptions,
      sortBy,
      updateSortBy,
      programNameWithFallbacks,
      enableTitleInterpolations,
      getInterpolatedFieldValue,
      enablePrintList,
      printPath,
    }
  },
  components: {
    AppGrid,
    AppCard,
    FilterInput,
    Filters,
    EmptyState,
    SkipToMain,
    SidebarWrapper,
    ProgramCard,
    FormSelect,
  },
  mixins: [clickaway],
  props: {
    filterFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterMenuOpened: false,
    }
  },
  head() {
    return {
      title: `Programs | ${this.catalogWideMetaTitle}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Programs | ${this.catalogWideMetaTitle}`,
        },
      ],
    }
  },
  computed: {
    ...mapState('settings', ['school']),
    ...mapState('departments', {
      departments: (state) => state.chosen || state.all,
    }),
    ...mapGetters('settings', ['catalogWideMetaTitle']),
    sortByOptionsWithEmptyValue() {
      return ['-', ...this.sortByOptions]
    },
    isFiltering() {
      return Object.values(this.filters).filter(
        (el) => (Array.isArray(el) ? el.length : el)
      ).length
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'searchDebounced',
    },
    '$route.query': 'fetchData',
  },
  async created() {
    this.isLoading = true
    await this.fetchQuestion()
    this.fetchData()
  },
  methods: {
    decamelize: decamelize,
    closeFilterMenu() {
      this.filterMenuOpened = false
    },
    programDisplayHeading(program) {
      return generateProgramLabel(program, this.school, {
        getInterpolatedFieldValue: this.enableTitleInterpolations
          ? this.getInterpolatedFieldValue
          : undefined,
      })
    },
  },
}
</script>

<style scoped>
.sidebar.filter-menu {
  @apply bg-gray-50 border border-gray-100;
}

h1 {
  color: var(--h1HeaderFontColor, var(--text-gray-900));
  font-family: var(--h1HeaderFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 700);
}
.filter-button {
  font-family: var(--interfaceFontTypeface, inherit);
  font-weight: var(--interfaceFontWeight, 700);
}

@media only screen and (min-width: 1024px) {
  .sidebar.filter-menu {
    @apply shadow-none top-auto left-auto relative h-auto rounded-lg z-0;

    transform: translateX(0px);
  }
}

.link-container {
  width: 240px;
  padding-top: 28px;
  padding-bottom: 28px;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--h2HeaderFontColor, var(--text-gray-900));
  font-family: var(--h2HeaderFontTypeface, inherit);
  font-weight: var(--h2HeaderFontWeight, 700);
}
</style>
